<template>
  <div class="table-container">
    <el-button
      type="primary"
      style="margin-bottom: 15px"
      @click="addBtn(true, null)"
    >
      添加分类
    </el-button>
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      element-loading-text="加载中"
      height="74vh"
      border
    >
      <el-table-column
        align="center"
        prop="id"
        min-width="90"
        show-overflow-tooltip
        label="ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="名称"
        min-width="180"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="update_time"
        label="修改时间"
        min-width="180"
      ></el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        show-overflow-tooltip
        label="操作面板"
        width="180px"
      >
        <template #default="{ row }">
          {{ row.status == 0 ? '正常' : '关闭' }}
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        show-overflow-tooltip
        label="操作面板"
        width="180px"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-popconfirm
            confirm-button-text="确认"
            cancel-button-text="取消"
            icon="el-icon-info"
            icon-color="red"
            title="确认删除此条？"
            @confirm="del(row)"
          >
            <el-button
              slot="reference"
              type="text"
              style="color: red; margin-left: 5px"
            >
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <!-- 新增/修改弹出层 -->
    <el-dialog
      :title="is_add ? '添加分类' : '修改分类'"
      :visible.sync="dialogFormVisible"
      top="15vh"
      width="800px"
      @close="close"
    >
      <el-form
        v-if="dialogFormVisible"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="标题" prop="name">
          <el-input
            v-model.trim="form.name"
            autocomplete="off"
            placeholder="请输入标题"
            maxlength="20"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    GoodsCate_list,
    GoodsCate_detail,
    GoodsCate_add,
    GoodsCate_update,
    GoodsCate_del,
  } from '@/api/xgcApi'
  export default {
    name: 'Edit',
    components: {},
    data() {
      return {
        rules: {
          name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          status: [
            {
              required: true,
              message: '请选择状态',
              trigger: ['blur', 'change'],
            },
          ],
        },
        dialogFormVisible: false, //增加修改弹出
        form: {
          name: '',
          status: 0,
        }, //提交/修改的表单数据
        list: [], //列表数据
        listLoading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        background: true,
        total: 0,
        is_add: true,
        queryForm: {
          page: 1,
          limit: 20,
        },
      }
    },
    beforeDestroy() {},
    mounted() {
      this.getList()
    },
    methods: {
      // 编辑
      handleEdit(row) {
        this.listLoading = true
        this.is_add = false
        GoodsCate_detail({ id: row.id })
          .then((res) => {
            this.form = res.data
            this.listLoading = false
            this.dialogFormVisible = true
          })
          .catch((err) => {
            this.listLoading = false
          })
      },
      submitForm() {
        // 提交表单
        var data = {
          name: this.form.name, //名称
          pid: '0', //上级id
          status: this.form.status, //0正常 1关闭
          pic: '', //图片备用没有为空
          pid_all: '', //层级id
        }
        this.listLoading = true
        if (this.is_add) {
          // 新增
          GoodsCate_add(data)
            .then((res) => {
              this.$message({
                type: 'success',
                message: '操作成功',
              })
              this.listLoading = false
              this.dialogFormVisible = false
              this.getList()
            })
            .catch((err) => {
              this.listLoading = false
            })
        } else {
          // 修改
          data.id = this.form.id
          GoodsCate_update(data)
            .then((res) => {
              this.$message({
                type: 'success',
                message: '操作成功',
              })
              this.listLoading = false
              this.dialogFormVisible = false
              this.getList()
            })
            .catch((err) => {
              this.listLoading = false
            })
        }
      },
      // 添加/修改
      addBtn(type, row) {
        if (type) {
          // 这个是新增
          this.is_add = true
        } else {
          // 这个是修改
          this.is_add = false
        }
        this.dialogFormVisible = true
      },
      // 对话框关闭
      close() {
        this.dialogFormVisible = false
        this.form = {}
      },
      del(row) {
        this.listLoading = true
        GoodsCate_del({
          id: row.id,
        })
          .then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功',
            })
            this.listLoading = false
            this.getList()
          })
          .catch((err) => {
            this.listLoading = false
          })
      },
      getList() {
        var data = {
          page: this.queryForm.page, //页码
          limit: this.queryForm.limit, //条数
        }
        this.listLoading = true
        GoodsCate_list(data)
          .then((res) => {
            this.list = res.data.list
            this.total = res.data.count
            this.listLoading = false
          })
          .catch((err) => {
            this.listLoading = false
          })
      },

      handleCurrentChange(e) {
        this.queryForm.page = e
      },
      handleSizeChange(e) {
        this.queryForm.limit = e
      },
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
</style>
