<template>
  <el-dialog
    v-loading="isLoading"
    :title="title"
    :visible.sync="dialogFormVisible"
    top="8vh"
    width="600px"
    element-loading-text="加载中"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="所属用户">
        <el-input
          v-model.trim="form.username + form.user_code"
          disabled
        ></el-input>
        <!--    <span>{{ form.username }}</span>
        <span>{{ form.user_code }}</span> -->
      </el-form-item>
      <el-form-item label="韩国单号">
        <el-input v-model.trim="form.express_code"></el-input>
      </el-form-item>
      <!-- 所属状态 -->
      <el-form-item label="订单状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择">
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.refuse_remark" label="驳回理由">
        <el-input v-model.trim="form.refuse_remark"></el-input>
      </el-form-item>
      <el-form-item label="购买平台">
        <el-input v-model.trim="form.platform"></el-input>
      </el-form-item>
      <el-form-item v-if="daifu" label="平台账号">
        <el-input v-model.trim="form.account"></el-input>
      </el-form-item>
      <el-form-item v-if="daifu" label="账号密码">
        <el-input v-model.trim="form.password"></el-input>
      </el-form-item>
      <el-form-item label="物品名称">
        <el-input v-model.trim="form.name"></el-input>
      </el-form-item>
      <el-form-item label="物品款式">
        <el-input v-model.trim="form.style"></el-input>
      </el-form-item>
      <el-form-item label="物品金额" prop="price">
        <el-input
          v-model.trim="form.price"
          maxlength="8"
          show-word-limit
          @input="handleInputPrice"
        ></el-input>
      </el-form-item>
      <el-form-item label="物品数量">
        <el-input
          v-model.trim="form.num"
          maxlength="10"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="添加时间" prop="add_time">
        <el-input v-model.trim="form.add_time" readonly></el-input>
      </el-form-item>
      <el-form-item v-if="daifu == true ? false : true" label="物品链接">
        <el-input
          v-model.trim="form.link"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 5 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="订单备注">
        <el-input
          v-model.trim="form.remark"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 5 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="物品图片">
        <el-image
          v-for="(item, index) in srcList"
          :key="index"
          style="width: 100px; height: 100px; margin-right: 10px"
          :src="item"
          :preview-src-list="srcList"
        ></el-image>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { buyDetail, buyStatus, buyUpdate } from '@/api/xgcApi'
  export default {
    name: 'UserManagementEdit',
    components: {},
    data() {
      return {
        form: {},
        title: '',
        srcList: [],
        dialogFormVisible: false,
        isLoading: false,
        rules: {
          price: [
            { required: true, trigger: 'blur', message: '请输入物品金额' },
          ],
        },
        daifu: false,
        statusList: [
          {
            value: 0,
            label: '待审核',
          },
          {
            value: 1,
            label: '待支付',
          },

          {
            value: 2,
            label: '代采购',
          },
          {
            value: 8,
            label: '已采购',
          },
          {
            value: 3,
            label: '已入库',
          },
          {
            value: 5,
            label: '已取消',
          },
          {
            value: 6,
            label: '退款中',
          },
          {
            value: 7,
            label: '已退款',
          },
          {
            value: 9,
            label: '已驳回',
          },
        ],
      }
    },

    created() {},
    methods: {
      handleInputPrice() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.price = this.form.price
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },

      async showEdit(row) {
        if (row.type == 2) {
          this.daifu = true
        } else {
          this.daifu = false
        }
        this.srcList = []
        this.dialogFormVisible = true
        this.title = '编辑'
        const { data } = await buyDetail({ id: row.id })
        this.form = data
        if (data.pic != null) {
          const chars = data.pic.split(',')
          this.srcList = chars
        }
      },

      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await buyUpdate(this.form)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped>
  :deep .el-select > .el-input {
    width: 430px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
