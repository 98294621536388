<template>
  <div class="table-container">
    <vab-query-form>
      <el-form
        ref="form"
        :model="queryForm"
        :inline="true"
        @submit.native.prevent
      >
        <el-form-item label="商品名称">
          <el-input
            v-model="queryForm.name"
            :clearable="true"
            placeholder="请输入商品名称"
          />
        </el-form-item>
        <el-form-item label="商品类型">
          <el-select
            v-model="queryForm.type"
            clearable
            placeholder="请选择"
            @keyup.enter.native="handleQuery"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            native-type="submit"
            @click="handleQuery"
          >
            查询
          </el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
            添加
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <el-table-column
        show-overflow-tooltip
        label="序号"
        width="120"
        align="center"
      >
        <template #default="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <!-- 商品类型 -->
      <el-table-column
        show-overflow-tooltip
        prop="type"
        label="商品类型"
        align="center"
      >
        <template slot-scope="{ row }">
          <div v-if="row.type == 1">补价商品</div>
          <div v-if="row.type == 2">代买商品</div>
        </template>
      </el-table-column>

      <!-- 商品名称 -->
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="商品名称"
        align="center"
      ></el-table-column>
      <!-- 商品名称 -->
      <el-table-column
        show-overflow-tooltip
        prop="price"
        label="商品金额"
        align="center"
      ></el-table-column>
      <!-- 商品图片 -->
      <el-table-column show-overflow-tooltip label="商品图片" align="center">
        <template #default="{ row }">
          <el-image v-if="imgShow" :src="row.pic"></el-image>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="分享图片" align="center">
        <template #default="{ row }">
          <el-image
            v-if="row.mini_code"
            :src="row.mini_code"
            @click="see_code_info(row)"
          ></el-image>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="创建时间"
        prop="update_time"
        width="250"
        align="center"
        sortable
      ></el-table-column>
      <el-table-column label="显示状态" align="center">
        <template slot-scope="{ row }">
          <el-switch
            v-model="row.status"
            style="display: block"
            :inactive-value="1"
            :active-value="0"
            @change="handleSwitchChange(row)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="是否推荐" align="center">
        <template slot-scope="{ row }">
          {{ row.recommend ? '推荐' : '不推荐' }}
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="220px"
        align="center"
        fixed="right"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>

          <el-popconfirm title="确认删除该商品？" @confirm="handleDelete(row)">
            <!-- <el-button slot="reference">删除</el-button> -->
            <el-button slot="reference" type="text" style="color: #ff0000">
              删除
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto"
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>

    <el-dialog title="分享图片" :visible.sync="code_img_show">
      <div style="display: flex; justify-content: center">
        <img :src="code_img" alt="" />
      </div>
    </el-dialog>
    <manage-goods
      ref="edit"
      :type-list="typeList"
      @fetch-data="getnewlist"
    ></manage-goods>
  </div>
</template>

<script>
  import {
    goodsList,
    goodsDel,
    goods_status,
    GoodsCate_list,
  } from '@/api/xgcApi'
  import manageGoods from '../components/manageGoods'
  export default {
    name: 'ComprehensiveTable',
    components: {
      manageGoods,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        code_img: '',
        code_img_show: false,
        form: {},
        imgShow: true,
        list: [],
        typeList: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
          name: '',
          type: '',
        },
        options: [
          {
            value: '0',
            label: '快递费',
          },
          {
            value: '1',
            label: '补价商品',
          },
          {
            value: '2',
            label: '代买商品',
          },
          {
            value: '3',
            label: '预约拍照',
          },
        ],
        height: '73vh',
      }
    },
    computed: {
      // height() {
      //   console.log( this.$baseTableHeight())
      //   var height =this.$baseTableHeight()+100
      //   console.log( height)
      //   return height
      // },
    },
    created() {
      this.fetchData()
      this.getTypelist()
    },

    beforeDestroy() {},
    mounted() {},
    methods: {
      getTypelist() {
        var data = {
          page: 1,
          limit: 100,
        }
        this.listLoading = true
        GoodsCate_list(data)
          .then((res) => {
            this.typeList = res.data.list
          })
          .catch((err) => {
            this.listLoading = false
          })
      },
      see_code_info(row) {
        this.code_img = row.mini_code
        this.code_img_show = true
      },
      deleteGoods(row) {
        // 删除商品
      },
      //修改状态
      async handleSwitchChange(row) {
        this.form.id = row.id
        this.form.status = row.status

        this.listLoading = true
        goods_status(this.form)
          .then((res) => {
            this.listLoading = false
            this.$message({
              type: 'success',
              message: '操作成功',
            })
          })
          .catch(() => {
            this.listLoading = false
          })
        // const { msg } = await goodsDel(this.form)
        // this.$baseMessage(msg, 'success')
      },
      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await goodsDel({ id: row.id })
          this.$baseMessage(msg, 'success')
          this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await goodsList(this.queryForm)
        this.list = data.list

        this.total = data.count

        const imageList = []
        data.list.forEach((item, index) => {
          this.list[index].pic = item.pic.split(',')[0]

          imageList.push(item.pic)
        })
        this.imageList = imageList
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
  .el-switch {
    height: auto !important;
  }
</style>
