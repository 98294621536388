<template>
  <div class="table-container">
    <el-button
      icon="el-icon-plus"
      type="primary"
      style="margin-bottom: 10px"
      @click="handleAdd"
    >
      添加
    </el-button>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <!-- 编码 -->
      <el-table-column
        show-overflow-tooltip
        prop="id"
        label="ID"
        sortable
        width="150"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="国家名称"
        prop="name"
        width="340"
        sortable
        align="center"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="上传时间"
        prop="create_time"
        width="340"
        sortable
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="250px"
        align="center"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button
            type="text"
            style="color: #ff0000"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <country ref="edit" @fetch-data="getnewlist"></country>
  </div>
</template>
<script>
  import {
    brandList,
    brandDel,
    KorPic_list,
    OrderPic_update,
    KorPic_country_del,
    KorPic_country_list,
  } from '@/api/xgcApi'
  import country from '../components/country'
  export default {
    name: 'ComprehensiveTable',
    components: { country },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height: '74vh',
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 10,
        },
        time: '',
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      handleEdit(row) {
        this.$refs['edit'].showEdit(row)
      },
      // 选择时间方法
      handleDateChange(value) {
        if (value != null) {
          this.queryForm.start_time = value[0]
          this.queryForm.end_time = value[1]
        } else {
          this.queryForm.start_time = ''
          this.queryForm.end_time = ''
        }
      },

      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      // handleEdit(row) {
      //   this.$refs['edit'].showEdit(row)
      // },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await KorPic_country_del({ id: row.id })
            this.$baseMessage(msg, 'success')
            this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await KorPic_country_del({ ids: ids })
              this.$baseMessage(msg, 'success')
              this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error')
            return false
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await KorPic_country_list(this.queryForm)
        const imageList = []
        data.list.forEach((item, index) => {
          imageList.push(item.pic)
        })

        this.imageList = imageList
        this.list = data.list
        this.total = data.count
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
</style>
