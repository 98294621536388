<template>
  <div class="table-container">
    <vab-query-form>
      <vab-query-form-left-panel>
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item label="上传时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="handleDateChange"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>

      <vab-query-form-right-panel>
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加
        </el-button>
        <!--        <el-button icon="el-icon-delete" type="danger" @click="handleDelete">
          删除
        </el-button> -->
      </vab-query-form-right-panel>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <!-- 编码 -->
      <el-table-column
        show-overflow-tooltip
        prop="id"
        label="序号"
        sortable
        width="150"
        align="center"
      ></el-table-column>

      <el-table-column show-overflow-tooltip label="包裹图片">
        <template #default="{ row }">
          <el-image :preview-src-list="imageList" :src="row.pic"></el-image>
        </template>
      </el-table-column>

      <el-table-column show-overflow-tooltip label="包裹状态">
        <template #default="{ row }">
          <div v-if="row.status == 0">未入库</div>
          <div v-if="row.status == 1">已入库</div>
          <div v-if="row.status == 2">待审核</div>
          <div v-if="row.status == 3">待打包</div>
          <div v-if="row.status == 4">待发货</div>
          <div v-if="row.status == 5">待支付</div>
          <div v-if="row.status == 6">转运中</div>
          <div v-if="row.status == 7">已签收</div>
          <div v-if="row.status == 8">审核拒绝</div>
          <div v-if="row.status == 9">退款中</div>
          <div v-if="row.status == 10">暂存</div>
          <div v-if="row.status == 11">退款中</div>
          <div v-if="row.status == 12">已退款</div>
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="上传时间"
        prop="create_time"
        width="340"
        sortable
        align="center"
      ></el-table-column>

      <el-table-column
        label="包裹单号"
        prop="code"
        width="340"
        sortable
        align="center"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.code"
            style="width: 100%"
            @change="handleEdit(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="250px"
        align="center"
      >
        <template #default="{ row }">
          <!-- <el-button type="text" @click="handleEdit(row)">编辑</el-button> -->
          <el-button
            type="text"
            style="color: #ff0000"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <!-- <manage-brand ref="edit" @fetch-data="getnewlist"></manage-brand> -->
  </div>
</template>
<script>
  import {
    brandList,
    brandDel,
    OrderPic_list,
    OrderPic_update,
    OrderPic_del,
  } from '@/api/xgcApi'
  export default {
    name: 'ComprehensiveTable',
    components: {},
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height: '74vh',
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 10,
          type: 1,
        },
        time: '',
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      // 选择时间方法
      handleDateChange(value) {
        if (value != null) {
          this.queryForm.start_time = value[0]
          this.queryForm.end_time = value[1]
        } else {
          this.queryForm.start_time = ''
          this.queryForm.end_time = ''
        }
      },
      handleEdit(index, row) {
        // 处理编辑逻辑，例如更新数据源等

        OrderPic_update(row).then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.msg,
              type: 'success',
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
            })
          }
          this.fetchData()
        })
      },
      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$refs['edit'].showEdit()
      },
      // handleEdit(row) {
      //   this.$refs['edit'].showEdit(row)
      // },
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await OrderPic_del({ id: row.id })
            this.$baseMessage(msg, 'success')
            this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await OrderPic_del({ ids: ids })
              this.$baseMessage(msg, 'success')
              this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'error')
            return false
          }
        }
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await OrderPic_list(this.queryForm)
        const imageList = []
        data.list.forEach((item, index) => {
          imageList.push(item.pic)
        })

        this.imageList = imageList
        this.list = data.list
        this.total = data.count
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }
</style>
