<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    style="z-index: 99"
    width="800px"
    @close="close"
  >
    <!-- 所属用户 -->
    <el-form ref="form" :model="form" label-width="76px" @submit.native.prevent>
      <!-- 包裹单号 -->
      <el-form-item label="包裹单号">
        <el-input
          ref="code1"
          v-model.trim="form.old_kr_code"
          autocomplete="off"
          clearable
          @keyup.enter.native="handleQuery"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-table :data="gridData" @selection-change="setSelectRows">
      <el-table-column
        v-if="shipping_type == '3'"
        show-overflow-tooltip
        type="selection"
        width="30"
        align="center"
      ></el-table-column>
      <el-table-column
        property="old_kr_code"
        label="包裹单号"
      ></el-table-column>
      <el-table-column v-if="shipping_type == '4'" label="部分出库" width="">
        <template #default="{ row }">
          <el-switch
            v-model="row.store_status"
            style="display: block"
            :inactive-value="1"
            :active-value="2"
          ></el-switch>
        </template>
      </el-table-column>

      <el-table-column
        label="备注"
        prop="remark"
        width="340"
        sortable
        align="center"
      >
        <template slot-scope="scope">
          <el-input v-model="scope.row.remark" style="width: 100%" />
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="">
        <template slot-scope="scope">
          <el-button
            type="text"
            style="color: red"
            size="small"
            @click.native.prevent="deleteRow(scope.$index, gridData)"
          >
            移除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <!--   <el-button type="primary" :loading="doPreviewLoading" @click="preview">
        预览面单
      </el-button> -->
      <el-button type="primary" :loading="printLoading" @click="printSheet">
        打印面单
      </el-button>
    </div>
    <!--   <el-dialog width="800px" title="出库" :visible.sync="innerVisible" append-to-body>
      <el-form ref="form1" :model="form1" label-width="76px" @submit.native.prevent>

        <el-form-item label="包裹单号">
          <el-input v-model.trim="form1.code" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="出库类型">
          <el-select v-model="form1.type" style="width: 455px" clearable placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库备注">
          <el-input v-model.trim="form1.remark" autocomplete="off" type="textarea" :rows="2" clearable></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="close1">取 消</el-button>
        <el-button type="primary" @click="chuku()">确认出库</el-button>
      </div>
    </el-dialog> -->
  </el-dialog>
</template>

<script>
  import {
    Order_codePrintCheck,
    OrderQuickDetail,
    pullingCheck,
    orderNotice,
    combinedBoxCheck,
    orderOutbound,
  } from '@/api/xgcApi'
  export default {
    name: 'Scan',
    components: {},
    data() {
      return {
        title: '',
        gridData: [],
        visible: false,
        loading: false,
        form: {
          old_kr_code: '',
          remark: '',
          store_status: 1,
        },
        form1: {
          code: '',
          remark: '',
          type: 1,
          shipping_type: 3,
          id: '',
          id_data: '',
        },
        dialogFormVisible: false,
        innerVisible: false,
        doPreviewLoading: false, //打印预览loading
        printLoading: false,
        id: '',
        selectRows: '', //多选
        shipping_type: '', // 包裹发货方式
        hid: '',
        options: [
          {
            value: 1,
            label: '全部出库',
          },
          {
            value: 2,
            label: '部分出库',
          },
        ],
      }
    },
    methods: {
      // 初始化请求数据
      async showEdit(row) {
        this.title = '扫描单号'
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs.code1.focus()
        })
      },
      // 多选,分箱选择
      setSelectRows(val) {
        this.selectRows = val
      },
      // 移除table数据
      deleteRow(index, rows) {
        rows.splice(index, 1)
      },
      // 扫描完包裹，处理回车键按下事件
      handleQuery(event) {
        if (this.form.old_kr_code != '') {
          this.getParcelType()
        }
      },
      // 快捷拉单查询包裹类型
      getParcelType() {
        OrderQuickDetail(this.form).then((res) => {
          this.shipping_type = res.data.shipping_type
          if (res.code == 200) {
            let newarr = []
            // 原箱发货
            if (res.data.shipping_type == 1) {
              newarr.push(res.data.id)
              this.pull(newarr)
            }
            // 分箱发货
            if (res.data.shipping_type == 3) {
              this.gridData = res.data.fen
            }
            // 合箱发货
            if (res.data.shipping_type == 4) {
              var data = {
                old_kr_code: this.form.old_kr_code,
                remark: this.form.remark,
                store_status: this.form.store_status,
              }
              this.gridData.push(data)
              this.hid = res.data.id
              this.form.old_kr_code = ''
              this.$nextTick(() => {
                this.$refs.code1.focus()
              })
            }
          }
        })
      },
      // 拉单接口
      pull(e) {
        var date = {
          id: e,
          type: 1,
        }
        orderNotice(date).then((res) => {
          if (res.code == 200) {
            if (this.shipping_type == 1) {
              var date = {
                code: this.form.old_kr_code,
                type: 1,
              }
              this.OutBound(date, e)
            }
            if (this.shipping_type == 3) {
              this.OutBound(this.form1, e)
            }
            if (this.shipping_type == 4) {
              this.gridData.forEach((item, index) => {
                var date = {
                  code: item.old_kr_code,
                  type: item.store_status,
                  remark: item.remark,
                }
                orderOutbound(date).then((res) => {
                  if (res.code == 200) {
                    this.$message({
                      type: 'success',
                      message: '出库成功',
                    })
                  }
                })
              })
              this.$emit('change', e)
              this.form.old_kr_code = ''
              this.$nextTick(() => {
                this.$refs.code1.focus()
              })
            }
          }
        })
      },
      //出库接口
      OutBound(date, event) {
        orderOutbound(date).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '出库成功',
            })
            this.$emit('change', event)
            this.form.old_kr_code = ''
            this.gridData = []
            this.$nextTick(() => {
              this.$refs.code1.focus()
            })
            if (this.shipping_type == 3) {
              this.close1()
            }
          }
        })
      },

      // // 拆包确认出库
      // chuku() {
      //   const ids = []
      //   this.selectRows.forEach((item, index) => {
      //     ids.push(item.id)
      //   })
      //   this.pull(ids)
      // },

      // 关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.gridData = []
        for (var key in this.form) {
          this.form[key] = null
        }
        this.dialogFormVisible = false
      },

      // // 关闭弹窗
      // close1() {
      //   this.$refs['form1'].resetFields()
      //   this.innerVisible = false
      // },

      // 打印面单
      printSheet() {
        if (this.gridData.length > 0) {
          let date = []
          // 分箱
          if (this.shipping_type == 3) {
            if (this.selectRows.length > 0) {
              if (this.selectRows.length == this.gridData.length) {
                this.form1.type = 1
              } else {
                this.form1.type = 2
              }
              var obj = {}
              this.gridData.forEach((item, index) => {
                if (item.remark) {
                  obj[item.id] = item.remark
                }
              })
              this.form1.id_data = obj
              this.form1.code = this.form.old_kr_code
              const ids = []
              this.selectRows.forEach((item, index) => {
                ids.push(item.id)
              })
              this.form1.id = ids
              this.pull(ids)
            } else {
              this.$baseMessage('未选中任何行', 'error')
              return false
            }
          }
          // 合箱发货
          if (this.shipping_type == 4) {
            this.gridData.forEach((item, index) => {
              date.push(item.old_kr_code)
            })
            combinedBoxCheck({
              old_kr_code: date.toString(),
              type: 4,
            })
              .then((res) => {
                if (res.code == 200) {
                  let arr1 = []
                  arr1.push(this.hid)
                  this.pull(arr1)
                }
                this.printLoading = false
              })
              .catch((error) => {
                this.printLoading = false
              })
          }
        } else {
          this.$baseMessage('请输入单号', 'warning')
        }
      },
      // 预览面单
      // preview() {
      //   if (this.gridData.length > 0) {
      //     this.doPreviewLoading = true
      //     let date = []
      //     this.gridData.forEach((item, index) => {
      //       date.push(item.code)
      //     })
      //     Order_codePrintCheck({
      //         code: date.toString()
      //       })
      //       .then((res) => {
      //         if (res.code == 200) {
      //           this.$baseMessage(res.msg, 'success')
      //           this.$emit('changes', res.data)
      //           this.gridData = []
      //           this.$nextTick(() => {
      //             this.$refs.code1.focus()
      //           })
      //         }
      //         this.doPreviewLoading = false
      //       })
      //       .catch((error) => {
      //         this.doPreviewLoading = false
      //       })
      //   } else {
      //     this.$baseMessage('请输入单号', 'warning')
      //   }
      // },
    },
  }
</script>
<style scoped lang="scss">
  :deep .el-input--small .el-input__inner {
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
