<template>
  <div class="table-container">
    <vab-query-form>
      <el-form
        ref="form"
        :model="queryForm"
        :inline="true"
        @submit.native.prevent
      >
        <el-form-item style="float: right">
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
            添加权限组
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form>

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <el-table-column
        show-overflow-tooltip
        label="序号"
        width="120"
        align="center"
      >
        <template #default="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <!-- 名称 -->
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="名称"
        align="center"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        label="创建时间"
        prop="add_time"
        width="250"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="150px"
        align="center"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDel(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--     -->
    <!-- 添加修改管理员对话框 -->
    <el-dialog
      :title="IsAdd ? '添加系统用户' : '修改用户详情'"
      :visible.sync="dialogVisible"
      width="50%"
      :destroy-on-close="true"
      :before-close="handleClose"
    >
      <div v-if="dialogVisible">
        <el-form ref="form" :rules="rules" :model="addForm" label-width="100px">
          <el-form-item label="权限组名称" prop="name">
            <el-input
              v-model="addForm.name"
              maxlength="20"
              type="text"
              placeholder="请输入用权限组名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="权限" prop="aid">
            <el-tree
              ref="tree"
              :check-strictly="true"
              :data="solidList"
              show-checkbox
              :default-expand-all="true"
              node-key="id"
              :default-checked-keys="addForm.aid"
              :props="defaultProps"
              @check="check"
            ></el-tree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="dialogVisible = false">
              取消
            </el-button>
            <el-button type="primary" @click="addSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {
    AdminGroup_update,
    AdminGroup_add,
    AdminGroup_list,
    AdminGroup_detail,
    AdminGroup_del,
    Auth_list,
  } from '@/api/xgcApi'

  export default {
    name: 'ComprehensiveTable',
    components: {},
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        defaultProps: {
          //树的配置
          children: 'children',
          label: 'title',
        },
        menuIds: [],
        gidList: [], //用户组列表
        solidList: [], //侧边栏列表
        rules: {
          name: [
            { required: true, message: '请输入权限组名称', trigger: 'blur' },
          ],
          aid: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (this.addForm.aid) {
                  callback()
                } else {
                  callback(new Error('请选择权限!'))
                }
              },
              trigger: ['blur', 'change'],
            },
          ],
        }, //添加修改管理员表单验证
        addForm: {}, //管理员信息
        IsAdd: true, //是否是添加 true添加 false修改
        dialogVisible: false, //添加管理员对话框
        form: {},
        imgShow: true,
        list: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
        },
        height: '73vh',
      }
    },
    computed: {},
    created() {
      this.fetchData()
      this.getSolidList()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      handleDel(row) {
        this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            AdminGroup_del({ id: row.id }).then((res) => {
              this.$message({
                type: 'success',
                message: '操作成功',
              })
              this.fetchData()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
      //调用check事件/下面三个方法都调用就可以实现，点击勾选父节点会跟随选中子节点/取消勾选父节点会跟随全部取消选中
      check(currentObj, treeStatus) {
        // 用于：父子节点严格互不关联时，父节点勾选变化时通知子节点同步变化，实现单向关联。
        let selected = treeStatus.checkedKeys.indexOf(currentObj.id)
        //不等于-1当前是选中状态
        if (selected !== -1) {
          this.selectedParent(currentObj)
          //统一处理子节点为相同的勾选状态
          this.uniteChildSame(currentObj, true)
        } else {
          //等于 -1 是取消选中状态
          this.uniteChildSame(currentObj, false) //
        }
        this.menuIds = this.$refs.tree.getCheckedKeys()
        this.addForm.aid = this.menuIds
      },
      uniteChildSame(treeList, isSelected) {
        this.$refs.tree.setChecked(treeList.id, isSelected)
        if (treeList.children) {
          for (let i = 0; i < treeList.children.length; i++) {
            this.uniteChildSame(treeList.children[i], isSelected)
          }
        }
      },
      selectedParent(currentObj) {
        let currentNode = this.$refs.tree.getNode(currentObj)
        if (currentNode.parent.key !== undefined) {
          this.$refs.tree.setChecked(currentNode.parent, true)
          this.selectedParent(currentNode.parent)
        }
      },
      getSolidList() {
        // 获取侧边栏列表
        Auth_list({}).then((res) => {
          this.solidList = res.data
        })
      },
      fetchData() {
        // 获取用户组列表
        var data = {
          page: 1, //页码
          limit: 50, //条数
        }
        AdminGroup_list(data)
          .then((res) => {
            this.listLoading = false
            this.list = res.data.list
          })
          .catch((err) => {
            this.listLoading = false
          })
      },
      addSubmit() {
        // 添加账号
        this.$refs['form'].validate((valid) => {
          if (valid) {
            var data = {
              name: this.addForm.name,
              aid: this.addForm.aid.join(','),
            }
            if (this.IsAdd) {
              AdminGroup_add(data).then((res) => {
                this.$message({
                  type: 'success',
                  message: '操作成功',
                })
                this.fetchData()
                this.dialogVisible = false
              })
            } else {
              data.id = this.addForm.id
              AdminGroup_update(data).then((res) => {
                this.$message({
                  type: 'success',
                  message: '操作成功',
                })
                this.fetchData()
                this.dialogVisible = false
              })
            }
          } else {
            return false
          }
        })
      },
      // 关闭添加修改对话框
      handleClose() {
        this.dialogVisible = false
      },
      //初始化方法
      getnewlist() {
        this.fetchData()
      },
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.addForm = {}
        this.IsAdd = true
        this.dialogVisible = true
      },
      handleEdit(row) {
        this.addForm = {}
        this.addForm.aid = []
        this.IsAdd = false
        this.listLoading = true
        var data = {
          id: row.id,
        }
        AdminGroup_detail(data)
          .then((res) => {
            this.listLoading = false
            this.dialogVisible = true
            res.data.aid = res.data.aid.split(',')
            this.addForm = res.data
          })
          .catch((err) => {
            this.listLoading = false
          })
      },

      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .el-switch {
    height: auto !important;
  }
</style>
