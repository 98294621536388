<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="a" itemtype="" style="width: 100px">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge class="item">
              <span style="width: 100px">全部</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待支付" name="b" itemtype="0">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status0" class="item">
              <span>待支付</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待发货" name="c">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status1" class="item">
              <span>待发货</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="退款中" name="d">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status2" class="item">
              <span>退款中</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已退款" name="e">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status3" class="item">
              <span>已退款</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
    </el-tabs>
    <!-- 搜索部分 -->
    <div class="table-container">
      <vab-query-form style="margin: 0">
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item label="订单编号">
            <el-input
              v-model="queryForm.code"
              placeholder="请输入订单编号"
              clearable
              style="width: 250px"
            />
          </el-form-item>

          <!-- <el-form-item label="商品类型">
            <el-select v-model="queryForm.gtype" clearable placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item> -->

          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              icon="el-icon-delete"
              type="danger"
              @click="handleDelete"
            >
              批量删除
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form>

      <el-table
        ref="tableSort"
        v-loading="listLoading"
        :data="list"
        :element-loading-text="elementLoadingText"
        :height="height"
        style="width: 100%"
        border
        @selection-change="setSelectRows"
        @sort-change="tableSortChange"
      >
        <el-table-column
          show-overflow-tooltip
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <!-- 用户编号 -->
        <el-table-column
          show-overflow-tooltip
          prop="user_code"
          label="用户编号"
          width="100"
        ></el-table-column>
        <!-- 商品图片 -->
        <el-table-column show-overflow-tooltip label="商品图片">
          <template #default="{ row }">
            <el-image :preview-src-list="imageList" :src="row.pic"></el-image>
          </template>
        </el-table-column>
        <!-- 订单编号 -->
        <el-table-column
          show-overflow-tooltip
          prop="code"
          label="订单编号"
          width="350"
        ></el-table-column>
        <!-- 运单号 -->
        <el-table-column
          show-overflow-tooltip
          prop="pay_code"
          label="支付单号"
          width="230"
        ></el-table-column>
        <!-- 支付状态 -->
        <el-table-column label="支付状态" align="center">
          <template #default="{ row }">
            <el-tag v-if="row.status == 0" size="small" type="warning">
              待支付
            </el-tag>
            <el-tag v-if="row.status == 1" size="small" type="success">
              待发货
            </el-tag>
            <el-tag v-if="row.status == 2" size="small">退款中</el-tag>
            <el-tag v-if="row.status == 3" size="small" type="info">
              已退款
            </el-tag>
          </template>
        </el-table-column>
        <!-- 订单金额 -->
        <el-table-column
          show-overflow-tooltip
          prop="price"
          label="订单金额"
        ></el-table-column>
        <!-- 退款金额 -->
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="refund_price"
          label="退款金额"
        >
          <template #default="{ row }">
            <span v-if="row.status == 3">{{ row.refund_price }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <!-- 订单重量 -->
        <el-table-column
          show-overflow-tooltip
          prop="num"
          label="订单数量"
        ></el-table-column>

        <el-table-column
          show-overflow-tooltip
          label="创建时间"
          prop="add_time"
          width="170"
          align="center"
          sortable
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          label="操作面板"
          min-width="190px"
          fixed="right"
        >
          <template #default="{ row }">
            <el-button type="primary" size="mini" @click="handledetails(row)">
              详情
            </el-button>
            <el-button type="danger" size="mini" @click="handleDelete(row)">
              删除
            </el-button>
            <el-button
              v-if="row.status == 2"
              type="warning"
              size="mini"
              @click="refund(row)"
            >
              退款
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :background="background"
        :current-page="queryForm.page"
        :layout="layout"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="queryForm.limit"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <commodity-list ref="edit" @fetch-data="getnewlist"></commodity-list>
      <tui-kuan ref="tuikuan" @change="getnewlist"></tui-kuan>
    </div>
  </div>
</template>

<script>
  import {
    goodsOrderDel,
    goodsOrderList,
    orderRefundRefund,
  } from '@/api/xgcApi'

  import commodityList from '../components/commodityList'
  import tuiKuan from '../components/tuikuan.vue'
  export default {
    name: 'ComprehensiveTable',
    components: {
      commodityList,
      tuiKuan,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height: '70vh',
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 20,
          status: '',
          gtype: '1',
        },
        value1: '',
        value: '',
        activeName: 'a',
        remindCount: {},
        isShowCode: false,
        options: [
          {
            value: '1',
            label: '补价商品',
          },
          {
            value: '2',
            label: '代买商品',
          },
        ],
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    methods: {
      importFile() {},
      // table，切换方法
      handleClick(tab, event) {
        switch (tab.name) {
          case 'a':
            this.queryForm.status = ''
            break
          case 'b':
            this.queryForm.status = 0
            break
          case 'c':
            this.queryForm.status = 1
            break
          case 'd':
            this.queryForm.status = 2
            break
          case 'e':
            this.queryForm.status = 3
            break
        }
        this.queryForm.page = 1
        this.fetchData()
      },
      getnewlist() {
        this.fetchData()
      },
      // 查看列表图片
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      // 多选
      setSelectRows(val) {
        this.selectRows = val
        this.chooseItem = val
      },

      // 点击详情
      handledetails(row) {
        this.$refs['edit'].showEdit(row)
      },
      // 点击退款
      refund(row) {
        // this.$prompt的message前面加必填标志 *
        var type = row.gtype == 1 ? 2 : 5
        this.$refs['tuikuan'].open(row, type)

        return
        // const h = this.$createElement
        // this.$prompt(
        //   h('p', null, [
        //     h('i', { style: 'color: red' }, '* '),
        //     h('span', null, '请输入申请理由：'),
        //   ]),
        //   '提示',
        //   {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     inputValue: '',
        //     inputPattern: /^.+$/,
        //     inputErrorMessage: '请输入内容',
        //   }
        // )
        //   .then(({ value }) => {
        //     var date = {
        //       id: row.id,
        //       price: row.price,
        //       type: row.gtype==1?2:5, //1正常包裹 2补拍 3代买 4代发 5代买商品
        //       refund_remarks: value, //退款理由
        //     }
        //     orderRefundRefund(date).then((res) => {
        //       if (res.code == 200) {
        //         this.fetchData()
        //         this.$baseMessage(res.msg, 'success')
        //       } else {
        //         this.$baseMessage(res.msg, 'error')
        //       }
        //     })
        //   })
        //   .catch((err) => {

        //   })
      },
      // 删除
      handleDelete(row) {
        if (row.id) {
          this.$baseConfirm('你确定要删除当前项吗', null, async () => {
            const { msg } = await goodsOrderDel({
              id: row.id,
            })
            this.$baseMessage(msg, 'success')
            this.fetchData()
          })
        } else {
          if (this.selectRows.length > 0) {
            const ids = this.selectRows.map((item) => item.id).join()
            this.$baseConfirm('你确定要删除选中项吗', null, async () => {
              const { msg } = await goodsOrderDel({
                id: ids,
              })
              this.$baseMessage(msg, 'success')
              this.fetchData()
            })
          } else {
            this.$baseMessage('未选中任何行', 'warning')
            return false
          }
        }
      },
      // 分页
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      // 分页
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      // 查询
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      // 请求数据
      async fetchData() {
        this.listLoading = true
        const { data } = await goodsOrderList(this.queryForm)
        this.list = data.list
        this.remindCount = data.statusCount
        this.total = data.count
        const imageList = []
        data.list.forEach((item, index) => {
          imageList.push(item.pic)
        })
        this.imageList = imageList
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>

<style scoped>
  :deep .el-button--mini {
    padding: 5px 11px;
  }

  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .demo-tabs .custom-tabs-label span {
    vertical-align: super;
    margin-left: 4px;
  }

  ::v-deep(.el-badge__content.is-fixed) {
    top: 11px;
    right: 17px;
  }

  .table-container {
    padding: 15px;
    padding-top: 30px;
    box-sizing: border-box;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }

  .el-tabs__item {
    width: 200px;
    /* 设置宽度为200px */
  }

  .el-badge {
    width: 60px !important;
  }

  ::v-deep(.el-tabs__header) {
    margin: 0 !important;
  }

  ::v-deep(.el-tabs__header span) {
    font-weight: bold;
  }

  .demo-table-expand {
    font-size: 0;
    padding-left: 150px;
    box-sizing: border-box;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 30%;
  }

  .dabaoStyle {
    background: #ffddaa;
    color: #999;
    border: 1px solid #fd5;
  }

  .fahuoStyle {
    background: #99dddd;
    color: #999;
    border: 1px solid 00FFFF;
  }

  .zhuanyunStyle {
    background: #ffffaa;
    color: #999;
    border: 1px solid FFFF00;
  }
</style>
