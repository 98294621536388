<template>
  <div>
    <el-dialog
      :title="add ? '添加平台链接' : '修改平台链接'"
      :visible.sync="isSHow"
      width="30%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="平台名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="平台链接">
          <el-input v-model="form.link"></el-input>
        </el-form-item>
        <el-form-item label="平台图片">
          <uploadFile
            :limt="1"
            :img-list-string="form.pic"
            @fileChange="uploadChange($event, 'file')"
          />
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            v-model="form.sort"
            type="text"
            maxlength="3"
            @input="
              (e) => {
                form.sort = e.replace(/\D/g, '')
              }
            "
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit(add)">
            {{ add ? '添加' : '修改' }}
          </el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import { Link_add, Link_update } from '@/api/xgcApi'
  import uploadFile from '@/components/uploadFile'
  export default {
    components: {
      uploadFile,
    },
    props: {
      isSHow: {
        type: Boolean,
        default: false,
      },
      form: {
        type: Object,
        default: {},
      },
      add: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    watch: {
      isSHow(val) {
        this.isSHow = val
      },
      form(val) {
        // this.form = val
      },
      add(val) {
        this.add = val
      },
    },
    mounted() {},
    methods: {
      uploadChange(e) {
        var strurl = e.join(',')
        strurl = strurl.replace('\\\\', '\/\/')
        strurl = strurl.replace('\\', '\/')
        strurl = strurl.replace('\\', '\/')
        this.form.pic = strurl
      },
      handleClose() {
        this.$emit('close', this.isSHow)
      },
      submit(type) {
        if (type) {
          // 这个是添加
          Link_add(this.form).then((res) => {
            this.$message({
              type: 'success',
              message: '添加成功',
            })
            this.$emit('submit')
          })
        } else {
          Link_update(this.form).then((res) => {
            this.$message({
              type: 'success',
              message: '修改成功',
            })
            this.$emit('submit')
          })
        }
      },
    },
  }
</script>

<style></style>
