import request from '@/utils/request'
import { encryptedData } from '@/utils/encrypt'
import { loginRSA, tokenName } from '@/config'

export async function login(data) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: 'admin/login/login',
    method: 'post',
    data,
  })
}

export function getUserInfo(accessToken) {
  // return request({
  //   url: '/userInfo',
  //   method: 'post',
  //   data: {
  //     [tokenName]: accessToken,
  //   },
  // })
  return {
    permissions: ['admin'],
    username: '111',
    avatar: '222',
  }
}

export function getUserDetail(data) {
  return request({
    url: 'admin/user/detail',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'),
    },
  })
}

// export function updateUser(data) {
//   return request({
//     url: 'admin/user/update',
//     method: 'post',
//     data: {
//       token: localStorage.getItem('tokenName'),
//       id: data.id,
//       name: data.name,
//       password: data.password,
//     },
//   })
// }

// 修改密码
export function updateUser(data) {
  return request({
    url: 'admin/user/update',
    method: 'post',
    data: data,
  })
}



export function logout(data) {
  return request({
    url: 'admin/login/logout',
    method: 'post',
    data,
  })
}

export function register() {
  return request({
    url: '/register',
    method: 'post',
  })
}
