<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="a" itemtype="" style="width: 100px">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge class="item">
              <span style="width: 100px">全部</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待审核" name="b" itemtype="0">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status0" class="item">
              <span>待审核</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="待支付待支付" name="c">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status1" class="item">
              <span>待支付</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="代采购" name="d">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status2" class="item">
              <span>代采购</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已采购" name="i">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status8" class="item">
              <span>已采购</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已入库" name="e">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status3" class="item">
              <span>已入库</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已取消" name="f">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status5" class="item">
              <span>已取消</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="退款中" name="g">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status6" class="item">
              <span>退款中</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已退款" name="h">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status7" class="item">
              <span>已退款</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="已驳回" name="k">
        <template #label>
          <span class="custom-tabs-label">
            <el-badge :value="remindCount.status9" class="item">
              <span>已驳回</span>
            </el-badge>
          </span>
        </template>
      </el-tab-pane>
    </el-tabs>

    <div class="table-container">
      <vab-query-form>
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item label="订单编号">
            <el-input
              v-model="queryForm.code"
              :clearable="true"
              placeholder="请输入订单编号"
            />
          </el-form-item>

          <el-form-item label="用户编号">
            <el-input
              v-model="queryForm.user_code"
              :clearable="true"
              placeholder="请输入用户编号"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
              添加订单
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button icon="" type="success" @click="BatchAudit">
              批量审核通过
            </el-button>
          </el-form-item>
          <el-button type="primary" @click="exportForm()">批量导出</el-button>
        </el-form>
      </vab-query-form>
      <el-table
        ref="tableSort"
        v-loading="listLoading"
        :data="list"
        :height="height"
        :element-loading-text="elementLoadingText"
        border
        @selection-change="setSelectRows"
        @sort-change="tableSortChange"
      >
        <el-table-column
          show-overflow-tooltip
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <!-- 用户唯一id -->
        <el-table-column
          show-overflow-tooltip
          prop="user_code"
          label="用户编号"
          align="center"
          width="100"
        ></el-table-column>
        <!-- 订单号 -->
        <el-table-column
          show-overflow-tooltip
          prop="code"
          label="订单编号"
          width="250"
          align="center"
        ></el-table-column>
        <!-- 运单号 -->
        <el-table-column
          show-overflow-tooltip
          prop="express_code"
          label="运单号"
          width="250"
          align="center"
        ></el-table-column>
        <!-- 物品名称 -->
        <el-table-column
          show-overflow-tooltip
          prop="name"
          label="物品名称"
          align="center"
        ></el-table-column>
        <!-- 物品款式 -->
        <el-table-column
          show-overflow-tooltip
          prop="style"
          label="物品款式"
          align="center"
        ></el-table-column>
        <!-- 数量 -->
        <el-table-column
          show-overflow-tooltip
          prop="num"
          label="数量"
          align="center"
        ></el-table-column>
        <!-- 价格 -->
        <el-table-column
          show-overflow-tooltip
          prop="price"
          label="价格"
          align="center"
        ></el-table-column>
        <!-- 退款金额 -->
        <el-table-column
          align="center"
          show-overflow-tooltip
          prop="refund_price"
          label="退款金额"
        >
          <template #default="{ row }">
            <span v-if="row.status == 7">{{ row.refund_price }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column label="状态" align="center">
          <template #default="{ row }">
            <el-tag v-if="row.status == 0" size="mini" type="danger">
              待审核
            </el-tag>
            <el-tag v-if="row.status == 1" size="mini" type="warning">
              待支付
            </el-tag>
            <el-tag v-if="row.status == 2" size="mini" type="primary">
              代采购
            </el-tag>
            <el-tag v-if="row.status == 3" size="mini" type="success">
              已入库
            </el-tag>
            <el-tag v-if="row.status == 5" size="mini" type="info">
              已取消
            </el-tag>
            <el-tag v-if="row.status == 6" size="mini" type="info">
              退款中
            </el-tag>
            <el-tag v-if="row.status == 7" size="mini" type="info">
              已退款
            </el-tag>
            <el-tag v-if="row.status == 8" size="mini" type="info">
              已采购
            </el-tag>
            <el-tag v-if="row.status == 9" size="mini" type="info">
              已驳回
            </el-tag>
          </template>
        </el-table-column>

        <!-- 购买平台 -->
        <el-table-column
          show-overflow-tooltip
          label="购买平台"
          prop="platform"
          align="center"
        ></el-table-column>
        <!-- 照片 -->
        <el-table-column show-overflow-tooltip label="图片" align="center">
          <template #default="{ row }">
            <el-image :preview-src-list="imageList" :src="row.pic"></el-image>
          </template>
        </el-table-column>
        <!-- 时间 -->
        <el-table-column
          sortable
          show-overflow-tooltip
          label="时间"
          prop="add_time"
          width="180"
          align="center"
        ></el-table-column>

        <el-table-column
          show-overflow-tooltip
          label="操作面板"
          fixed="right"
          width="280"
        >
          <template #default="{ row }">
            <el-button type="primary" size="mini" @click="handleEdit(row)">
              编辑
            </el-button>
            <el-button size="mini" type="danger" @click="handleDelete(row)">
              删除
            </el-button>
            <el-button
              v-if="row.status == 6"
              type="warning"
              size="mini"
              @click="refund(row)"
            >
              退款
            </el-button>
            <el-popconfirm
              v-if="row.status == 0"
              title="是否确认本次操作？"
              @confirm="passAudit(row)"
            >
              <el-button
                slot="reference"
                style="margin-left: 10px"
                type="success"
                size="mini"
              >
                审核通过
              </el-button>
            </el-popconfirm>
            <el-button
              v-if="row.status == 0"
              type="danger"
              size="mini"
              style="margin-left: 10px"
              @click="reject(row)"
            >
              驳回
            </el-button>
            <el-button
              v-if="row.status == 8"
              type="primary"
              plain
              size="mini"
              style="margin-left: 10px"
              @click="storageProcessing(row)"
            >
              入库处理
            </el-button>
            <el-button
              v-if="row.status == 2"
              type="primary"
              plain
              size="mini"
              style="margin-left: 10px"
              @click="Purchased(row)"
            >
              已采购
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :background="background"
        :current-page="queryForm.page"
        :layout="layout"
        :page-sizes="[20, 40, 60, 100]"
        :page-size="queryForm.limit"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      ></el-pagination>
      <pay-detail ref="edit" @fetch-data="getnewlist"></pay-detail>
      <add-pay ref="edit1" @fetch-data="getnewlist"></add-pay>
      <tui-kuan ref="tuikuan" @change="getnewlist"></tui-kuan>
      <reject ref="reject" @change="getnewlist"></reject>
    </div>
  </div>
</template>

<script>
  import {
    buyList,
    buyDel,
    buyStatus,
    orderRefundRefund,
    BuyExcel_out,
  } from '@/api/xgcApi'
  import addPay from '../components/addPay.vue'
  import payDetail from '../components/payDetail.vue'
  import tuiKuan from '../components/tuikuan.vue'
  import reject from '../components/reject.vue'
  import { getUploadUrl } from '@/config'
  export default {
    name: 'ComprehensiveTable',
    components: {
      payDetail,
      addPay,
      tuiKuan,
      reject,
    },
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height: '70vh',
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          type2: 2,
          page: 1,
          limit: 20,
          status: '', //0待审核 1待支付 2代采购 3已入库 5已取消
        },
        value: '',
        activeName: 'a', //表单切换初始化，显示全部
        remindCount: {}, //提示小红点数据
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    methods: {
      // 驳回点击
      reject(row) {
        this.$refs['reject'].showEdit(row)
      },
      // 批量导出
      exportForm() {
        if (this.selectRows.length > 0) {
          this.$baseConfirm('确定导出已选', null, async () => {
            const ids = this.selectRows.map((item) => item.id).join()
            const { data } = await BuyExcel_out({
              id: ids,
            })
            const fileUrl = getUploadUrl + data
            window.open(fileUrl)
            this.$message({
              type: 'success',
              message: '导出成功',
            })
          })
        } else {
          this.$baseMessage('未选中任何行', 'warning')
          return false
        }
      },
      // 批量审核
      BatchAudit() {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join()
          var date = {
            status: 1,
            id: ids,
          }
          buyStatus(date).then(({ data }) => {
            this.$message({
              type: 'success',
              message: '操作成功',
            })
            this.queryForm.status = ''
            this.fetchData()
          })
        } else {
          this.$baseMessage('未选中任何行', 'warning')
          return false
        }
      },
      // 已采购
      Purchased(row) {
        var data = {
          id: row.id,
          status: 8, //0待审核 1待支付 2代采购 3已入库 5已取消
        }
        buyStatus(data).then((res) => {
          this.queryForm.status = 8
          this.activeName = 'i'
          this.$message({
            type: 'success',
            message: '操作成功',
          })
          this.fetchData()
        })
      },
      // table，切换方法
      handleClick(tab, event) {
        switch (tab.name) {
          case 'a':
            this.queryForm.status = ''
            break
          case 'b':
            this.queryForm.status = 0
            break
          case 'c':
            this.queryForm.status = 1
            break
          case 'd':
            this.queryForm.status = 2
            break
          case 'e':
            this.queryForm.status = 3
            break
          case 'f':
            this.queryForm.status = 5
            break
          case 'g':
            this.queryForm.status = 6
            break
          case 'h':
            this.queryForm.status = 7
            break
          case 'i':
            this.queryForm.status = 8
            break
          case 'k':
            this.queryForm.status = 9
            break
        }
        this.queryForm.page = 1
        this.fetchData()
      },
      // 审核通过
      async passAudit(row) {
        this.queryForm.status = 1
        this.queryForm.price = row.price
        this.queryForm.id = row.id
        buyStatus(this.queryForm).then(({ data }) => {
          this.$message({
            type: 'success',
            message: '操作成功',
          })
          this.queryForm.status = ''
          this.fetchData()
        })
      },
      // 入库处理操作
      storageProcessing(row) {
        this.$router.push({
          path: './inStorage',
          query: {
            id: row.id,
          },
        })
      },

      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$refs['edit1'].showEdit({
          type2: 2,
        })
      },
      handleEdit(row) {
        this.$refs['edit'].showEdit({
          id: row.id,
          type: 2,
        })
      },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await buyDel({
            id: row.id,
          })
          this.$baseMessage(msg, 'success')
          this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.queryForm.status = this.value
        this.fetchData()
      },
      // 点击退款
      refund(row) {
        this.$refs['tuikuan'].open(row, 3)
        // this.$prompt的message前面加必填标志 *
        // const h = this.$createElement
        // this.$prompt(
        //   h('p', null, [
        //     h('i', { style: 'color: red' }, '* '),
        //     h('span', null, '请输入申请理由：'),
        //   ]),
        //   '提示',
        //   {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     inputValue: '',
        //     inputPattern: /^.+$/,
        //     inputErrorMessage: '请输入内容',
        //   }
        // )
        //   .then(({ value }) => {
        //     var date = {
        //       id: row.id,
        //       price: row.price,
        //       type: 3, //1正常包裹 2补拍 3代买 4代发 5代买商品
        //       refund_remarks: value, //退款理由
        //     }
        //     orderRefundRefund(date).then((res) => {
        //       if (res.code == 200) {
        //         this.fetchData()
        //         this.$baseMessage(res.msg, 'success')
        //       } else {
        //         this.$baseMessage(res.msg, 'error')
        //       }
        //     })
        //   })
        //   .catch((err) => {})
      },
      async fetchData() {
        this.listLoading = true
        const { data } = await buyList(this.queryForm)
        var newlist = data.list
        newlist.forEach((item, index) => {
          if (item.pic != null) {
            var commaIndex = item.pic.indexOf(',')
            if (commaIndex > 1) {
              let result = item.pic.substring(0, commaIndex)
              item.pic = result
            }
            this.imageList.push(item.pic)
          }
        })
        this.list = newlist
        this.remindCount = data.statusCount
        this.total = data.count
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
      getnewlist() {
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .table-container {
    min-height: calc(100%);
  }

  :deep .el-button--mini {
    padding: 5px 11px;
  }

  .table-container {
    padding: 15px;
    padding-top: 30px;
    box-sizing: border-box;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }

  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  ::v-deep(.el-badge__content.is-fixed) {
    top: 11px;
    right: 17px;
  }

  .el-tabs__item {
    width: 200px;
    /* 设置宽度为200px */
  }

  .el-badge {
    width: 60px !important;
  }

  ::v-deep(.el-tabs__header) {
    margin: 0 !important;
  }

  ::v-deep(.el-tabs__header span) {
    font-weight: bold;
  }
</style>
