<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="正式库位" name="a">
        <template #label>
          <span>正式库位</span>
          </span>
        </template>
      </el-tab-pane>
      <el-tab-pane label="临时库位" name="b" >
        <template #label>
          <span>临时库位</span>
          </span>
        </template>
      </el-tab-pane>
    </el-tabs>
    <div class="table-container">

      <div class="use_box">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="库位号">
            <el-select v-model="queryForm.storage_location" filterable clearable placeholder="请选择"
              @change="handleChange">
              <el-option v-for="item in option" :key="item.store_number" :label="item.store_number"
                :value="item.store_number" />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
        <el-table v-loading="listLoading" :data="list" border resizable style="width: 100%"
          :element-loading-text="elementLoadingText">
          <el-table-column label="库位号" prop="store_number" show-overflow-tooltip />
          <el-table-column label="库位状态">
            <template #default="{ row }">
              <div v-if="row.store_status == 1">正常使用</div>
              <div v-if="row.store_status == 0" style="color: red">
                库位已禁用
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作库位" width="" fixed="right">
            <template #default="{ row }">
              <el-switch v-model="row.store_status" style="display: block" :inactive-value="0" :active-value="1"
                @change="handleSwitchChange(row)"></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    orderGetAllStorageLocation,
    orderStorageLocationEnable,
    orderStorageLocationDisable,
  } from '@/api/xgcApi'
  export default {
    data() {
      return {
        list: null,
        listLoading: true,
        elementLoadingText: '正在加载...',
        queryForm: {
          storage_location: '',
          type: 1
        },
        option: [],
        timeOutID: null,
        activeName: 'a',
      }
    },
    created() {
      this.getKuweiList()
    },
    beforeDestroy() {
      clearTimeout(this.timeOutID)
    },
    methods: {
      // 切换分类
      handleClick(tab, event) {
        switch (tab.name) {
          case 'a':
            this.queryForm.type = 1
            break
          case 'b':
            this.queryForm.type = 2
            break
        }
        this.getKuweiList()
      },
      // 获取/更新库位列表数据
      getKuweiList() {
        this.listLoading = true
        var arr = []
        orderGetAllStorageLocation(this.queryForm).then((res) => {
          this.list = res.data
          this.option = res.data
          this.timeOutID = setTimeout(() => {
            this.listLoading = false
          }, 300)
        })
      },
      // 开关选择
      handleSwitchChange(e) {
        if (e.store_status == 0) {
          orderStorageLocationDisable({
            store_number: e.store_number
          }).then(
            (res) => {}
          )
        } else {
          orderStorageLocationEnable({
            store_number: e.store_number
          }).then(
            (res) => {}
          )
        }
        this.getKuweiList()
      },
      // 选择位置
      handleChange(value) {

        this.queryForm.storage_location = value
      },
      // 点击查询
      queryData() {
        this.listLoading = true
        orderGetAllStorageLocation(this.queryForm).then((res) => {
          if (this.queryForm.storage_location) {
            res.data.forEach((item, index) => {
              if (this.queryForm.storage_location == item.store_number) {
                this.list = []
                this.list.push(item)
              }
            })
          } else {
            this.list = res.data
          }
          this.timeOutID = setTimeout(() => {
            this.listLoading = false
          }, 300)
        })
      },
    },
  }
</script>
<style scoped>
  .use_box {
    background-color: #fff;
    /* padding: 5px; */
    box-sizing: border-box;
  }

  .table-container {
    padding: 15px;
    box-sizing: border-box;
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
  }

  ::v-deep(.el-tabs__header) {
    margin: 0 !important;
  }

  ::v-deep(.el-tabs__header span) {
    font-weight: bold;
  }
</style>
