<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    @close="close"
  >
    <!-- 所属用户 -->
    <el-form ref="form" :model="form" label-width="76px" @submit.native.prevent>
      <!-- 包裹单号 -->
      <el-form-item label="包裹单号">
        <el-input
          v-model.trim="form.code"
          autocomplete="off"
          disabled
          @keyup.enter.native="handleQuery"
        ></el-input>
      </el-form-item>
      <!--      <el-form-item label="运单号">
        <el-input
          v-model.trim="form.express_code"
          autocomplete="off"
          disabled
          @keyup.enter.native="handleQuery"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="出库类型">
        <el-select
          v-model="form.type"
          style="width: 455px"
          clearable
          placeholder="请选择"
          @keyup.enter.native="handleQuery"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出库备注">
        <el-input
          v-model.trim="form.remark"
          autocomplete="off"
          type="textarea"
          :rows="2"
          clearable
          @keyup.enter.native="handleQuery"
        ></el-input>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="chuku()">确认出库</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { Form } from 'element-ui'
  import { Order_codePrintCheck, orderOutbound } from '@/api/xgcApi'
  export default {
    name: 'Out',
    components: {},
    data() {
      return {
        title: '包裹出库',
        gridData: [],
        visible: false,
        form: {
          code: '',
          order_id: '',
          remark: '',
          type: 1,
        },
        dialogFormVisible: false,
        options: [
          {
            value: 1,
            label: '全部出库',
          },
          {
            value: 2,
            label: '部分出库',
          },
        ],
      }
    },
    methods: {
      // 初始化请求数据
      async showEdit(row) {
        this.dialogFormVisible = true
        this.form.code = row.code
        this.form.express_code = row.express_code
        this.form.order_id = row.id
      },
      // 确认出库
      chuku() {
        var date = {
          code: this.form.code,
          type: this.form.type,
          remark: this.form.remark,
        }
        // if (this.form.express_code) {
        orderOutbound(date).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '出库成功',
            })
            this.$emit('change', res.data)
            this.close()
          }
        })
        // } else {
        //   this.$message({
        //     type: 'warning',
        //     message: '禁止出库',
        //   })
        // }
      },
      // 关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
    },
  }
</script>
<style scoped lang="scss">
  :deep .el-input--small .el-input__inner {
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
