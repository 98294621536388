<template>
  <el-dialog
    v-loading="isLoading"
    :title="title"
    :visible.sync="dialogFormVisible"
    top="8vh"
    width="600px"
    element-loading-text="加载中"
    @close="close"
  >
    <el-form
      v-if="dialogFormVisible"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
    >
      <!-- 所属状态 -->
      <el-form-item
        label="退款金额"
        prop="price_tui"
        style="margin-bottom: 15px"
      >
        <el-input
          v-model="form.price_tui"
          type="text"
          show-word-limit
          maxlength="10"
          :placeholder="placeholder"
          @input="inputChange"
        ></el-input>
      </el-form-item>
      <el-form-item label="申请原因" prop="dsc">
        <el-input
          v-model="form.dsc"
          show-word-limit
          type="textarea"
          maxlength="50"
          placeholder="请输入申请原因"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { orderRefundRefund } from '@/api/xgcApi'
  export default {
    name: 'UserManagementEdit',
    components: {},
    data() {
      return {
        dialogFormVisible: false,
        isLoading: false,
        form: {
          price_tui: null, //退款金额
          dsc: '', //退款原因
        },
        title: '申请退款',
        placeholder: '',
        rules: {
          price_tui: [
            { required: true, message: '请输退款金额', trigger: 'change' },
          ],
          dsc: [{ required: true, message: '请输入', trigger: 'change' }],
        },
        type: 1,
        price: [{ required: true, trigger: 'blur', message: '请输入物品金额' }],
      }
    },

    created() {},
    methods: {
      close() {
        this.dialogFormVisible = false
      },
      inputChange(e) {
        this.form.price_tui = this.$geShi(e)
        if (this.form.price_tui > this.form.price) {
          this.form.price_tui = this.form.price
        }
      },
      open(row, type) {
        this.form = { ...this.form, ...row }
        this.type = type
        this.max = row.price
        this.placeholder = `退团金额不能超过${row.price}元`
        this.form.price_tui = null
        this.form.dsc = ''
        this.dialogFormVisible = true
      },
      save() {
        let row = this.form
        var date = {
          id: row.id,
          price: this.form.price_tui,
          type: this.type, //1正常包裹 2补拍 3代买 4代发 5代买商品
          refund_remarks: this.form.dsc, //退款理由
        }
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.isLoading = true
            orderRefundRefund(date)
              .then((res) => {
                this.$baseMessage('操作成功', 'success')
                this.$emit('change', true)
                this.dialogFormVisible = false
                this.isLoading = false
              })
              .catch((err) => {
                this.isLoading = false
              })
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped>
  :deep .el-select > .el-input {
    width: 430px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
