<template>
  <div class="userManagement-container">
    <div class="use_box">
      <el-form :inline="true" :model="queryForm" @submit.native.prevent>
        <!--    <el-form-item  label="">
          <el-select v-model="queryForm.customerId" clearable placeholder=" ('public.Please_select')" @keyup.enter.native="fetchData">
            <el-option v-for="item in options" :key="item.id"  label="item.userName" :value="item.id" />
          </el-select>
        </el-form-item> -->
        <el-form-item label="用户编号">
          <el-input
            v-model.trim="queryForm.user_code"
            clearable
            placeholder="请输入用户编号"
            @keyup.enter.native="fetchData"
          />
        </el-form-item>
        <el-form-item label="包裹单号">
          <el-input
            v-model.trim="queryForm.code"
            clearable
            placeholder="请输入包裹单号"
            @keyup.enter.native="fetchData"
          />
        </el-form-item>
        <el-form-item label="库位号">
          <el-input
            v-model.trim="queryForm.storage_location"
            clearable
            placeholder="请输入库位号"
            @keyup.enter.native="fetchData"
          />
        </el-form-item>
        <!--       <el-form-item label="库位号">
          <el-select
            v-model="queryForm.storage_location"
            filterable
            clearable
            placeholder="请选择"
            @change="handleChange"
          >
            <el-option
              v-for="item in options1"
              :key="item.store_number"
              :label="item.store_number"
              :value="item.store_number"
            />
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="queryData">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="listLoading"
        :data="list"
        border
        resizable
        style="width: 100%"
        :element-loading-text="elementLoadingText"
        @selection-change="setSelectRows"
      >
        <!-- <el-table-column show-overflow-tooltip type="selection" /> -->
        <el-table-column
          label="用户名称"
          prop="user_name"
          show-overflow-tooltip
          width="100"
        />
        <el-table-column
          label="用户编号"
          prop="user_code"
          show-overflow-tooltip
          width="120"
        />
        <el-table-column
          label="包裹单号"
          prop="old_kr_code"
          show-overflow-tooltip
          width="260"
        />
        <!--        <el-table-column
          label="运单号"
          prop="express_code"
          show-overflow-tooltip
        /> -->
        <!--        <el-table-column label="商品体积(cm)" show-overflow-tooltip width="160">
          <template #default="{ row }">
            长 {{ row.length }} 宽{{ row.width }} 高 {{ row.height }}
          </template>
        </el-table-column>
        <el-table-column  label=" ('palletsNumber')" prop="palletsNumber" width="80" show-overflow-tooltip />
        <el-table-column  label=" ('boxesNumber')" prop="boxesNumber" width="80" show-overflow-tooltip /> -->
        <el-table-column
          label="库位号"
          prop="storage_location"
          show-overflow-tooltip
        />
        <el-table-column label="库存天数">
          <template #default="{ row }">
            <div v-if="row.storedays > 30" style="color: red">
              {{ row.storedays }}天
            </div>
            <div v-else>{{ row.storedays }}天</div>
          </template>
        </el-table-column>
        <el-table-column
          label=" 入库时间"
          prop="store_time"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="操作">
          <template #default="{ row }">
            <el-button type="success" @click="chuku(row)">出库</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.limit"
        :total="total"
        style="margin-top: 10px"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <out ref="out" @change="getNewList"></out>
  </div>
</template>

<script>
  import {
    // wmsinventoryList,
    orderKuwei,
    orderGetAllStorageLocation,
    orderCheckout,
    // user_selectUser
    // wmspositionListAll
  } from '@/api/xgcApi'
  // import {
  //   getToken
  // } from '@/utils/auth'
  // import axios from 'axios'
  // import store from '@/store'
  import out from '../components/out'
  export default {
    components: {
      out,
    },
    data() {
      return {
        exportLoading: false,
        list: null,
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 10,
          itemName: '',
          itemNo: '',

          customerId: '',
          storage_location: '',
        },
        options1: [],
        options: [],
        timeOutID: null,
      }
    },
    created() {
      this.fetchData()
      // this.getUserList()
      this.getLocalInfo()
    },
    beforeDestroy() {
      clearTimeout(this.timeOutID)
    },
    methods: {
      // 出库操作
      chuku(row) {
        this.$refs['out'].showEdit(row)
        return
        orderCheckout({ code: e.code }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '出库成功',
            })
          }
        })
      },
      // 称重机扫描打印
      getNewList(data) {
        this.fetchData()
      },
      // 选择位置
      handleChange(value) {
        this.queryForm.storage_location = value
        // const selectedLabel = this.options1.find(
        //   (option) => option.id === value
        // ).positionNo
        // this.queryForm.positionNo = selectedLabel
      },
      // getUserList() {
      //   orderGetAllStorageLocation().then((res) => {
      //     console.log(res)
      //     return
      //     this.options = res.userList
      //   })
      // },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      queryData() {
        this.queryForm.page = 1
        this.fetchData()
      },
      // 获取位置编号列表
      getLocalInfo() {
        var data = {}
        orderGetAllStorageLocation(data).then((res) => {
          this.options1 = res.data
        })
      },
      // 获取库存列表
      async fetchData() {
        this.listLoading = true
        const { data } = await orderKuwei(this.queryForm)

        // return
        this.list = data.data
        this.total = data.count
        this.timeOutID = setTimeout(() => {
          this.listLoading = false
        }, 300)
      },
    },
  }
</script>
<style>
  .use_box {
    background-color: #fff;
    /* padding: 5px; */
    box-sizing: border-box;
  }
</style>
