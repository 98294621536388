<template>
  <!-- 仓库管理————组件 -->
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="收件人" prop="username">
        <el-input
          v-model.trim="form.username"
          autocomplete="off"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 编码 -->
      <el-form-item label="编码" prop="code">
        <el-input
          v-model.trim="form.code"
          autocomplete="off"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 邮编 -->
      <el-form-item label="邮编" prop="ems">
        <el-input
          v-model.trim="form.ems"
          autocomplete="off"
          placeholder="请输入"
          maxlength="10"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 仓库名 -->
      <el-form-item label="仓库名" prop="name">
        <el-input
          v-model.trim="form.name"
          autocomplete="off"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 省市区 -->
      <el-form-item label="省市区" prop="city">
        <el-input
          v-model.trim="form.city"
          autocomplete="off"
          placeholder="请输入"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 地址 -->
      <el-form-item label="地址" prop="address">
        <el-input
          v-model.trim="form.address"
          autocomplete="off"
          placeholder="请输入"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 电话 -->
      <el-form-item label="电话" prop="mobile">
        <el-input
          v-model.trim="form.mobile"
          autocomplete="off"
          placeholder="请输入"
          maxlength="15"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { valid } from 'mockjs'
  import { warehouseUdate, warehouseDetail } from '@/api/xgcApi'

  export default {
    name: 'ManageStore',
    data() {
      return {
        form: {},
        rules: {
          code: [{ required: true, trigger: 'blur', message: '请输入编码' }],
          ems: [{ required: true, trigger: 'blur', message: '请输入邮编' }],
          name: [
            { required: true, trigger: 'blur', message: '请输入仓库名称' },
          ],
          city: [{ required: true, trigger: 'blur', message: '请输入省市区' }],
          address: [{ required: true, trigger: 'blur', message: '请输入地址' }],
          mobile: [{ required: true, trigger: 'blur', message: '请输入电话' }],
        },
        title: '',
        dialogFormVisible: false,
      }
    },
    created() {},
    methods: {
      async showEdit(row) {
        this.title = '编辑'
        const { data } = await warehouseDetail({ id: row.id })
        this.form = data
        this.dialogFormVisible = true
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.$emit('fetch-data')
      },
      save() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const { msg } = await warehouseUdate(this.form)
            this.$baseMessage(msg, 'success')
            this.$refs['form'].resetFields()
            this.dialogFormVisible = false
            this.$emit('fetch-data')
            this.form = this.$options.data().form
          } else {
            return false
          }
        })
      },
    },
  }
</script>
