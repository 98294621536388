import { render, staticRenderFns } from "./commodityList.vue?vue&type=template&id=ff81d9ca&scoped=true&"
import script from "./commodityList.vue?vue&type=script&lang=js&"
export * from "./commodityList.vue?vue&type=script&lang=js&"
import style0 from "./commodityList.vue?vue&type=style&index=0&id=ff81d9ca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff81d9ca",
  null
  
)

export default component.exports