<template>
  <div>
    <!-- <el-button type="text" @click="Visible = true">点击打开 Dialog</el-button> -->
    <el-dropdown @command="handleCommand">
      <span class="avatar-dropdown">
        <!--<el-avatar class="user-avatar" :src="avatar"></el-avatar>-->
        <!-- <img class="user-avatar" :src="avatar" alt="" /> -->
        <div class="user-name">
          {{ userDetail.name }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
      </span>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="information" divided>
          修改密码
        </el-dropdown-item>
        <el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
      class="demo-ruleForm"
      title="修改密码"
      :visible.sync="dialogVisible"
      append-to-body
      width="30%"
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        ref="userDetail"
        :model="userDetail"
        :rules="rules"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="admin">
          <el-form-item label="登陆账号" prop="name">
            <el-input v-model="userDetail.name" :disabled="true"></el-input>
          </el-form-item>
          <!-- <el-form-item label="用户名称">
              <el-input :disabled="true" v-></el-input>
            </el-form-item> -->
        </div>

        <el-form-item label="修改密码" prop="password">
          <el-input
            v-model="userDetail.password"
            autocomplete="off"
            show-password
            maxlength="16"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input
            v-model="userDetail.confirmPassword"
            autocomplete="off"
            show-password
            maxlength="16"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="resetForm('adminForm')">重置</el-button> -->
        <el-button @click="cancell">取 消</el-button>
        <el-button type="primary" @click="submitForm('userDetail')">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { recordRoute } from '@/config'
  import { getUserDetail, updateUser } from '@/api/user'

  export default {
    name: 'VabAvatar',
    data() {
      return {
        // Visible: false,
        token: {},
        userDetail: {
          id: '',
          name: '',
          password: '',
          confirmPassword: '',
          gid: '',
        },
        adminForm: {
          password: '',
          confirmPassword: '',
        },
        //控制修改密码表单的显示和隐藏
        dialogVisible: false,
        rules: {
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 6, message: '密码长度不能少于6位', trigger: 'blur' },
          ],
          confirmPassword: [
            { required: true, message: '请再次输入密码', trigger: 'blur' },
            { min: 6, message: '密码长度不能少于6位', trigger: 'blur' },
            { validator: this.validateConfirmPassword, trigger: 'blur' },
          ],
        },
      }
    },
    computed: {
      ...mapGetters({
        avatar: 'user/avatar',
        username: 'user/username',
      }),
    },
    mounted() {
      this.getinfo()
    },
    methods: {
      async getinfo() {
        const { data } = await getUserDetail(this.token)
        this.userDetail.name = data.name
        this.userDetail.id = data.id
        this.userDetail.gid = data.gid
      },
      handleCommand(command) {
        switch (command) {
          case 'logout':
            this.logout()
            break
          case 'personalCenter':
            this.personalCenter()
            break
          case 'github':
            window.open('https://github.com/chuzhixin/vue-admin-beautiful')
            break
          case 'gitee':
            window.open('https://gitee.com/chu1204505056/vue-admin-beautiful')
            break
          case 'information':
            this.information()
            break
        }
      },
      handleClose(done) {
        this.userDetail.password = ''
        this.userDetail.confirmPassword = ''
        this.dialogVisible = false
      },

      personalCenter() {
        this.$router.push('/personalCenter/personalCenter')
      },
      async information() {
        this.userDetail.password = ''
        this.userDetail.confirmPassword = ''
        const { data } = await getUserDetail(this.token)
        this.userDetail.name = data.name
        this.userDetail.id = data.id
        this.userDetail.gid = data.gid
        this.dialogVisible = true
      },
      //自定义表单校验 修改密码时 校验两次输入的密码是否一致
      validateConfirmPassword(rule, value, callback) {
        if (value === this.userDetail.password) {
          callback()
        } else {
          callback(new Error('两次输入密码不一致'))
        }
      },

      logout() {
        this.$baseConfirm(
          '您确定要退出' + this.$baseTitle + '吗?',
          null,
          async () => {
            await this.$store.dispatch('user/logout')
            if (recordRoute) {
              const fullPath = this.$route.fullPath
              this.$router.push(`/login?redirect=${fullPath}`)
            } else {
              this.$router.push('/login')
            }
          }
        )
      },
      //提交修改密码的表单
      submitForm(userDetail) {
        this.$refs[userDetail].validate(async (valid) => {
          if (valid) {
            const { msg } = await updateUser(this.userDetail)
            this.$baseMessage(msg, 'success')
            this.dialogVisible = false
          }
        })
      },
      cancell() {
        this.dialogVisible = false
        this.userDetail.password = ''
        this.userDetail.confirmPassword = ''
        this.clearValidation()
      },
      clearValidation() {
        // 清除密码和确认密码字段的校验结果
        this.$refs.userDetail.clearValidate('password')
        this.$refs.userDetail.clearValidate('confirmPassword')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .avatar-dropdown {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: 50px;
    padding: 0;

    .user-avatar {
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 50%;
    }

    .user-name {
      position: relative;
      margin-left: 5px;
      margin-left: 5px;
      cursor: pointer;
    }

    .demo-ruleForm {
      font-size: 16px;
      width: 80%;
      height: 200px;
      background-color: rgba(128, 128, 128, 0.5);
      margin-top: 28px;
      padding-left: 30px;
    }
  }
</style>
